import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

/*import Tour from './views/Tour.vue';
import TourList from './views/TourList.vue';
import Home from './views/Home.vue';
import Search from './views/Search.vue';*/



const app = createApp(App)
app.use(router)
app.mount('#app')




