<template>
  <div id="app">
    <h1>Hiking Tours (Vue3, SFC)</h1>
    <Navigation></Navigation>

  </div>
</template>

<script>
import Navigation from './components/Navigation.vue';


export default {
  name: 'App',
  components: {
    Navigation
  },


  
}
</script>


<style></style>