<template>
    <h2>Overview</h2>
    <TourList></TourList>
</template>
  
<script>
import TourList from '../components/TourList.vue';

export default {
    name: 'Overview',
    components: {
        TourList
    },
    data() {
        return {

        }
    }
}
</script>