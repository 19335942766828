<template>
  <!-- TourListFilter Component --->
  <TourListFilter 
    v-bind:tourlist="tourlist" 
    @filtered-tours="filteredTourList = $event"
    @reset-start-item-index="startItemIndex = 0">
  </TourListFilter>
  <!-- Ausgabe Tourliste --> 
  <h2>Ausgabe mit Filter</h2>
  <!-- Pagination Component --> 
  <h3>totalPages: {{ totalPages }}</h3>
  <TourListPagination
    :totalItems="totalPages" 
    :itemsPerPage="10"
    :startItemIndex="startItemIndex"
    @change-start-item-index="startItemIndex = $event">
  </TourListPagination>
  <div v-for="tour in paginatedTourList" :key="tour.id">
    <span>* {{ tour.titel }} - {{ tour.id }}</span>
    <a href="#" @click.stop="linkToRoute(tour.id)">Link</a>
<br>
  </div>
</template>


<script>
//import Navigation from './components/Navigation.vue';
import TourListFilter from '../components/TourListFilter.vue';
import TourListPagination from './TourListPagination.vue';

export default {
  name: 'Tourlist',
  components: {
    TourListFilter,
    TourListPagination
  },
  data() {
    return {
      baseUrl: "http://wandern.cube-online.de/",
      startItemIndex: 0,
      totalItems: "",
      tourlist: [],
      filteredTourList: []
    }
  },
  methods: {
    async fetchTourlist() {
      console.log("fetch BaseURL", this.baseUrl)
      const res = await fetch(this.baseUrl + 'content/data.json')
      const data = await res.json()
      return data

    },
    linkToRoute(id) {
      console.log("push...", id);
      localStorage.setItem('tours', JSON.stringify(this.tourlist));
      this.$router.push({
        name: "tour-page",
        params: {
          id: id
        },
      });
    },
  },
  computed: {
    paginatedTourList() {
      if (this.filteredTourList !== undefined) {
        return this.sortedTourlist.slice(this.startItemIndex, this.startItemIndex + 10);
      } else {
        return 0;
      }    
    },
    sortedTourlist() {
        function compare(a, b) {
            if (a.id > b.id)
                return -1;
            if (a.id < b.id)
                return 1;
            return 0;
        }
        if (this.filteredTourList !== undefined) {
          return this.filteredTourList.sort(compare);
        } else {
          return 0
        }
    },
    totalPages() {
      if (this.filteredTourList !== undefined) {
        return  this.filteredTourList.length;
      }
      else { 
         return 0;
      }  
    }
  },  
  async mounted() {
    // console.log("BASE_URL is " + process.env.BASE_URL);
    console.log("mode is " + process.env.NODE_ENV);
    if (process.env.NODE_ENV === "development") {
      this.baseUrl = "http://localhost:8080/"
    } 
    this.tourlist = await this.fetchTourlist();
    console.log("mounted this.TourList", this.tourlist.length);
    console.log("mounted this.filteredTourList", this.filteredTourList.length);
    if (this.filteredTourList.length === 0) { 
      this.filteredTourList = this.tourlist;
    }
    this.totalItems = this.getTotalPages;
    console.log("mounted after set this.filteredTourList", this.filteredTourList.length);
  }
}
</script>