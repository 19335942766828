<template>
    <h2>Tour</h2>

    <!-- Summary -->
    <p>tourId is {{ $route.params.id }}</p>
    <div>title:
        title: {{ tour.titel }} <br>
        id: {{ tour.id }} <br>
        bildanzahl {{ tour.bildanzahl }}<br>
        untertitel {{ tour.untertitel }}

    </div>
    <!-- Page Content -->
    <div v-html="pageContent"></div>
    <!-- Image Gallery FsLightbox VueComponent -->
    <h3>Images Gallery</h3>
    <div>
        <ul>
            <li v-for="tourImage in tourImages" :key="tourImage.id">
                <img :src="tourImage"  width="200" height="150"/>
            </li>
        </ul>
    </div>
</template>


<script>


export default {
    name: 'Tour',
    components: {

    },
    data: function () {
        return {
            tour: "",
            tourId: this.$route.params.id,
            tourlist: [],
            pageContent: "",
            tourImages: [],
            basePathImages: "/content/fotos/",
            baseUrl: ""
        }
    },
    mounted() {
        // this.tourId = this.$route.params.tourId;
        console.log("this.$route.params =", this.$route.params.id)
        console.log("tourId =", this.tourId)
        this.tourlist = JSON.parse(localStorage.getItem('tours'));
        //console.log("tourlist =", localStorage.getItem('tours'));

        this.tour = this.getTourById(this.tourId);
        this.loadPageContent();
        this.loadPageImages();
    },
    methods: {
        async loadPageContent() {
            try {
                const response = await fetch("http://localhost:8080/content/" + this.tourId + ".html");
                if (response.status === 404) {
                    this.pageContent = "sorry folks, no content found."
                } else {
                    const data = await response.text()
                    this.pageContent = data;
                }
            } catch (error) {
                console.log("error:" + error);
            }
        },
        getTourById(id) {
            return this.tourlist.find(x => x.id === id);
        },
        loadPageImages() {
            for (var i = 1; i <= this.tour.bildanzahl; i++) {
                var currentImage = this.baseUrl + this.basePathImages + this.tourId + "/0" + i + ".jpg"
                this.tourImages.push(currentImage);
            }
        },
    }
};



</script>