<template>
    <div>
        <section>
            <nav>
                <ul class="pagination">
                    <li><a href="#" @click="decrementIndex" v-if="hasPrevious">zuruck</a> </li>
                    <li>{{firstPageItem }} -  {{lastPageItem }}/ {{ totalItems }}</li>
                    <li><a href="#" @click="incrementIndex" v-if="hasNext">weiter</a> </li>
                </ul>
            </nav>
        </section>
    </div>
</template>

<script>

export default {
    name: "TourListPagination",
    props: [ "totalItems","itemsPerPage", "startItemIndex"],
    data: function () {
        return {
            modifiedStartItemIndex: this.startItemIndex,
            firstPageItem: this.startItemIndex + 1,
            lastPageItem: 0,
            // lastPageItem: this.startItemIndex + this.itemsPerPage + 1,
            hasNext: true,
            hasPrevious: false

        }
    },
    methods: {
        getLastPageItem() {
            this.lastPageItem = this.startItemIndex + this.itemsPerPage + 1;
            if (this.lastPageItem > this.totalItems) {
                this.lastPageItem = this.totalItems
            }
        },
        incrementIndex() {
            this.hasPrevious = true;
            if (this.modifiedStartItemIndex < this.totalItems - 10) {
                this.modifiedStartItemIndex += this.itemsPerPage;
                this.firstPageItem +=  this.itemsPerPage;   
                this.lastPageItem  +=  this.itemsPerPage; 
                this.hasNext = true; 
                if (this.lastPageItem >= this.totalItems) {
                    this.lastPageItem = this.totalItems;
                    this.hasNext = false;
                }
            }
            this.$emit('change-start-item-index', this.modifiedStartItemIndex);
        },
        decrementIndex() {
            this.hasNext = true;
            if (this.modifiedStartItemIndex > 0) {
                this.modifiedStartItemIndex -= this.itemsPerPage;
                this.firstPageItem -=  this.itemsPerPage;
                this.lastPageItem  -=  this.itemsPerPage;
                this.hasPrevious = true;      
            } else {
                this.hasPrevious = false;
            }
            this.$emit('change-start-item-index', this.modifiedStartItemIndex);
        },
        resetPagination() {
                this.modifiedStartItemIndex = 0;
                this.firstPageItem = this.startItemIndex + 1;
                this.lastPageItem = this.startItemIndex + this.itemsPerPage + 1;
                this.hasNext = true,
                this.hasPrevious = false
        }        
    },
    watch: {
        // Pagenav Werte resetten, wenn startItemIndex 0 ist 
        startItemIndex: function () {
            console.log("Watch: startItemIndex changed to ", this.startItemIndex);
            if (this.startItemIndex === 0) {
                this.resetPagination();
            }
        },
        totalItems: function() {
            this.hasNext = true;
            console.log("in watcher: this.totaslItems", this.totalItems);
            this.getLastPageItem();
            console.log("in watcher: this.lastPageItem", this.lastPageItem);
            if (this.lastPageItem >= this.totalItems) {
                this.hasNext = false;
            }    
        }
    },
    mounted() {
        console.log ("startItemIndex =", this.startItemIndex);

        console.log ("lastPageItem", this.lastPageItem);
        console.log ("totalItems", this.totalItems);
    }
};
</script>

<style scoped>
ul.pagination {
    background-color: #E9E9E9;
}

ul.pagination > li {
    display: inline;
}
</style>