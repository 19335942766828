import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Overview from '../views/Overview.vue';
import Tour from '../views/Tour.vue';


const routes = [
  { path: '/', component: Home},
  { path: '/tourlist', component: Overview},  
  { path: '/tour/:id', name: "tour-page", component: Tour},     
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
